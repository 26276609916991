import { motion } from "framer-motion";

export default function Footer() {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5, duration: 0.5 }}
      className="container mx-auto px-4 py-8 mt-16 border-t border-gray-300 dark:border-gray-700"
    >
      <p className="text-center text-gray-600 dark:text-gray-400">
        © 2024 Alejandro Rebecchi. All rights reserved.
      </p>
    </motion.footer>
  );
}
