import { useEffect, useState } from "react";
import { fadeIn, stagger, titleAnimation } from "../../animation.utils";
import { Progress } from "../ui/progress";
import { motion, Variants } from "framer-motion";

function getYearsSince(dt: Date) {
  return Math.abs(
    new Date(new Date().getTime() - dt.getTime()).getUTCFullYear() - 1970
  );
}

const skills = [
  { name: "Python", years: getYearsSince(new Date("July, 1, 2013")) },
  { name: "Django", years: getYearsSince(new Date("July, 1, 2013")) },
  { name: "Javascript", years: 8 },
  { name: "React", years: getYearsSince(new Date("February, 1, 2019")) },
  { name: "AWS", years: 4 },
  { name: "JQuery", years: 4 },
  { name: "Java", years: 3 },
  { name: "Google Cloud Platform", years: 3 },
  { name: "React Native", years: 1 },
  { name: "Angular", years: 1 },
];

type SkillsProps = {
  activeSection: string;
  sections: string[];
  sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> };
};

export default function Skills({
  sections,
  activeSection,
  sectionRefs,
}: SkillsProps) {
  const [activated, setActivated] = useState(false);
  const sectionName = "skills";

  useEffect(() => {
    if (activated) {
      return;
    }
    if (
      sections.findIndex((section) => section === activeSection) >=
      sections.findIndex((section) => section === sectionName)
    ) {
      setActivated(true);
    }
  }, [activated, sections, activeSection]);
  return (
    <motion.section
      ref={sectionRefs.skills}
      id="skills"
      className="py-16 px-4"
      {...fadeIn}
    >
      <div className="container mx-auto">
        {activated ? (
          <>
            <motion.h2
              className="text-3xl font-bold mb-8 text-center"
              {...titleAnimation}
            >
              Skills
            </motion.h2>
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 gap-6"
              variants={stagger}
              initial="initial"
              animate="animate"
            >
              {skills.map((skill) => (
                <motion.div
                  key={skill.name}
                  variants={fadeIn as Variants}
                  className="bg-white dark:bg-gray-700 rounded-lg p-4 shadow-md"
                >
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-semibold">{skill.name}</h3>
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      {skill.years} years
                    </span>
                  </div>
                  <Progress value={skill.years * 20} className="h-2" />
                </motion.div>
              ))}
            </motion.div>
          </>
        ) : (
          <>
            <h2 className="text-transparent text-3xl font-bold mb-8">.</h2>
            <div className="opacity-0 grid grid-cols-1 md:grid-cols-2 gap-6">
              {skills.map((skill) => (
                <div key={skill.name} className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-semibold">.</h3>
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      years
                    </span>
                  </div>
                  <Progress value={0} className="h-2" />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </motion.section>
  );
}
