import { fadeIn, titleAnimation } from "@/animation.utils";
import ShinyButton from "../magicui/shiny-button";
import { motion } from "framer-motion";
import { GithubIcon, GitlabIcon, LinkedinIcon, MailIcon } from "lucide-react";
import { useEffect, useState } from "react";

type AboutProps = {
  activeSection: string;
  sections: string[];
  sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> };
};

export default function About({
  activeSection,
  sections,
  sectionRefs,
}: AboutProps) {
  const [activated, setActivated] = useState(false);
  const sectionName = "about";

  useEffect(() => {
    if (activated) {
      return;
    }
    if (
      sections.findIndex((section) => section === activeSection) >=
      sections.findIndex((section) => section === sectionName)
    ) {
      setActivated(true);
    }
  }, [activated, sections, activeSection]);

  return (
    <motion.section
      ref={sectionRefs.about}
      id="about"
      className="py-16 px-4 bg-gray-200 dark:bg-gray-800"
      {...fadeIn}
    >
      <div className="container mx-auto">
        {activated ? (
          <motion.h2
            className="text-3xl font-bold mb-8 text-center"
            {...titleAnimation}
          >
            About Me
          </motion.h2>
        ) : (
          <h2 className="text-transparent text-3xl font-bold mb-8">.</h2>
        )}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <p className="text-lg mb-4 md:mb-0 md:mr-8 flex-1">
            I started working as a web developer in 2010, and used a wide
            variety of languages and frameworks. In 2017 I got my Master's
            Degree in Computer Science from the University of Buenos Aires.
          </p>
          <div className="flex flex-wrap justify-start md:justify-end gap-4">
            <ShinyButton
              className="bg-white dark:bg-slate-800 dark:text-white border border-slate-300 dark:border-slate-700"
              onClick={() =>
                window.open("mailto:contacto@alejandrorebecchi.es")
              }
            >
              <MailIcon className="w-4 h-4 mr-2" />
              contacto@alejandrorebecchi.es
            </ShinyButton>
            {/* <Button variant="outline" size="sm">
                <PhoneIcon className="w-4 h-4 mr-2" />
                (123) 456-7890
              </Button> */}
            <ShinyButton
              className="bg-white dark:bg-slate-800 dark:text-white border border-slate-300 dark:border-slate-700"
              onClick={() => window.open("https://github.com/cheverebe/")}
            >
              <GithubIcon className="w-4 h-4 mr-2" />
              GitHub
            </ShinyButton>
            <ShinyButton
              className="bg-white dark:bg-slate-800 dark:text-white border border-slate-300 dark:border-slate-700"
              onClick={() => window.open("https://gitlab.com/cheverebe")}
            >
              <GitlabIcon className="w-4 h-4 mr-2" />
              GitLab
            </ShinyButton>

            <ShinyButton
              className="bg-white dark:bg-slate-800 dark:text-white border border-slate-300 dark:border-slate-700"
              onClick={() => {
                window.open("https://www.linkedin.com/in/alejandro-rebecchi/");
              }}
            >
              <LinkedinIcon className="w-4 h-4 mr-2" />
              LinkedIn
            </ShinyButton>
          </div>
        </div>
      </div>
    </motion.section>
  );
}
