import "./App.css";
import NavBar from "./components/navbar";
import Hero from "./components/hero";
import About from "./components/about";
import Services from "./components/service";
import Experience from "./components/experience";
import Portfolio from "./components/portfolio";
import Footer from "./components/footer";
import Loader from "./components/loader";
import NewCv from "./home";

function App() {
  return (
    <>
      <NewCv />
      {/* <NavBar />
      <Hero />
      <About />
      <Experience />
      <Services />
      <Portfolio />
      <Footer />
      <a className="btn back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
      <Loader /> */}
    </>
  );
}

export default App;
