import { fadeIn, stagger, titleAnimation } from "@/animation.utils";
import { motion, Variants } from "framer-motion";
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";

const inkedVoicesImage = require("../../static/img/portfolio-iv.jpg");
const meetSofieImage = require("../../static/img/portfolio-ms.jpg");
const zoomistImage = require("../../static/img/portfolio-zm.png");
const shakeASipperImage = require("../../static/img/portfolio-sap.jpg");
const image_ds = require("../../static/img/portfolio-ds.jpg");
const captureEchoImage = require("../../static/img/portfolio-cc.png");
const tailorMadeImage = require("../../static/img/portfolio-tmn.png");

const ALL = "All";
const WEB_DEVELOPMENT = "Web Development";
const MOBILE_APPS = "Mobile Apps";
const PLUGINS = "Plugins/Extensions";
const CATEGORIES = [ALL, WEB_DEVELOPMENT, MOBILE_APPS, PLUGINS];
const items = [
  {
    image: captureEchoImage,
    name: "Capture Echo",
    link: "https://captureecho.com",
    categories: [WEB_DEVELOPMENT],
  },
  {
    image: tailorMadeImage,
    name: "Tailor Made News",
    link: "https://tailormadenews.com",
    categories: [WEB_DEVELOPMENT],
  },
  {
    image: shakeASipperImage,
    name: "Shake A Sipper",
    link: "https://shakeasipper.com",
    categories: [WEB_DEVELOPMENT, MOBILE_APPS],
  },
  {
    image: image_ds,
    name: "Daisy Sunset",
    link: "https://daisysunset.com",
    categories: [WEB_DEVELOPMENT],
  },
  {
    image: meetSofieImage,
    name: "Meet Sofie",
    link: "https://chromewebstore.google.com/detail/sofie/dlbdgakbihkdninofdlghefchnhiihaa?hl=es",
    categories: [WEB_DEVELOPMENT, PLUGINS],
  },
  {
    image: inkedVoicesImage,
    name: "Inked Voices",
    link: "https://inkedvoices.com",
    categories: [WEB_DEVELOPMENT],
  },
  {
    image: zoomistImage,
    name: "Zoomist.com",
    link: "https://zoomist.com/",
    categories: [WEB_DEVELOPMENT],
  },
];

const projectCards = items.map((item) => (
  <motion.div variants={fadeIn as Variants}>
    <Card>
      <CardContent className="p-6">
        <div className="aspect-video relative mb-4 overflow-hidden rounded-lg">
          <img
            src={item.image}
            alt="Task Management App Preview"
            style={{ objectFit: "cover", objectPosition: "center" }}
            className="transition-transform duration-300 hover:scale-105"
          />
        </div>
        <h3 className="text-xl font-semibold mb-2">{item.name}</h3>
        {/* <p className="mb-4">
        {item.description}
      </p> */}
        <a href={item.link} target="_blank">
          <Button variant="outline" size="sm">
            View Project
          </Button>
        </a>
      </CardContent>
    </Card>
  </motion.div>
));

type ProjectsProps = {
  activeSection: string;
  sections: string[];
  sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> };
};
export default function Projects({
  activeSection,
  sections,
  sectionRefs,
}: ProjectsProps) {
  const [activated, setActivated] = useState(false);
  const [childrenctivated, setChildrenActivated] = useState(false);
  const sectionName = "projects";

  useEffect(() => {
    if (activated) {
      return;
    }
    if (
      sections.findIndex((section) => section === activeSection) >=
      sections.findIndex((section) => section === sectionName)
    ) {
      setActivated(true);
      setTimeout(() => {
        setChildrenActivated(true);
      }, 600);
    }
  }, [activated, sections, activeSection]);
  return (
    <motion.section
      ref={sectionRefs.projects}
      id="projects"
      className="py-16 px-4"
      {...fadeIn}
    >
      <div className="container mx-auto">
        {activated ? (
          <>
            <motion.h2
              className="text-3xl font-bold mb-8 text-center"
              {...titleAnimation}
            >
              Projects
            </motion.h2>

            {childrenctivated ? (
              <motion.div
                className="grid grid-cols-1 md:grid-cols-2 gap-8"
                variants={stagger}
                initial="initial"
                animate="animate"
              >
                {projectCards}
              </motion.div>
            ) : (
              <div
                className="grid grid-cols-1 md:grid-cols-2 gap-8"
                style={{ opacity: 0 }}
              >
                {projectCards}
              </div>
            )}
          </>
        ) : (
          <>
            <h2 className="text-transparent text-3xl font-bold mb-8">.</h2>
            <div
              className="grid grid-cols-1 md:grid-cols-2 gap-8"
              style={{ opacity: 0 }}
            >
              {projectCards}
            </div>
          </>
        )}
      </div>
    </motion.section>
  );
}
