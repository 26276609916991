import { fadeIn, stagger, titleAnimation } from "@/animation.utils";
import { animate, delay, motion, Variants } from "framer-motion";
import { MagicCard } from "../magicui/magic-card";
import { CardContent } from "../ui/card";
import { useEffect, useState } from "react";

const experience: {
  dates: string;
  title: string;
  company: string;
  description: string;
  actions?: string[];
  skills?: string[];
}[] = [
  {
    dates: "2021 - 2024",
    title: "Tech Lead & Sr. Backend Engineer",
    company: "Thanks Ben Ltd (Remote)",
    description:
      "Backend development with Python/Django, frontend development with JavaScript/React and mobile development with React native.",
    skills: [
      "Python",
      "Django",
      "Django Rest Framework",
      "React",
      "JavaScript",
      "React Native",
      "AWS",
      "Github",
      "CI/CD",
      "PostgreSQL",
    ],
  },
  {
    dates: "2017 - Present",
    title: "Tech lead & Full Stack Developer",
    company: "Inked Voices (Remote)",
    description:
      "Backend development with Python/Django, frontend development with JavaScript/React and devops with Digital Ocean.",
    skills: [
      "Python",
      "Django",
      "Django Rest Framework",
      "React",
      "JavaScript",
      "Digital Ocean",
      "Gitlab",
      "CI/CD",
      "MySQL",
    ],
  },
  {
    dates: "2019 - 2021",
    title: "Freelance Full Stack Developer",
    company: "Toptal LLC (Freelance)",
    description:
      "Backend development with Python/Django, frontend development with JavaScript/React and devops with AWS and GCP.",
    skills: [
      "Python",
      "Django",
      "Django Rest Framework",
      "React",
      "JavaScript",
      "Google Cloud",
      "Github",
      "CI/CD",
      "PostgreSQL",
    ],
  },
  {
    dates: "2018 - 2019",
    title: "Software Engineer",
    company: "Medallia",
    description: "Backend development with Java/Spring.",
    skills: ["Java", "Spring", "Github", "PostgreSQL"],
  },
  {
    dates: "2015 - 2018",
    title: "Backend Developer",
    company: "Google (Globant contractor)",
    description:
      "Backend development with Python (Django), Google Cloud (App Engine, Compute Engine, Cloud Storage, SQL, BigQuery, Cloud Datasore).",
    skills: [
      "Python",
      "Google App Engine",
      "Google Cloud",
      "Jenkins",
      "Google BigQuery",
      "Google Cloud SQL",
      "Google Cloud Datastore",
    ],
  },
  {
    dates: "2013 - 2015",
    title: "Web Developer",
    company: "Devartis",
    description:
      "Cassandra, Elasticsearch, Ruby(on Rails), Python(Django), Javascript(JQuery, Backbone), PHP(codeigniter), CSS.",
    skills: ["Python", "Django", "JavaScript", "Github", "MySQL"],
  },
  {
    dates: "2010 - 2013",
    title: "Web Developer",
    company: "Aufiero Informática SRL",
    description:
      "PHP (Codeigniter, Wordpress), Java (Grails), Javascript(JQuery), MySQL.",
    skills: [
      "PHP",
      "Code Igniter",
      "Wordpress",
      "Java",
      "Grails",
      "JavaScript",
      "JQuery",
      "Github",
      "MySQL",
    ],
  },
];

type ExperienceProps = {
  activeSection: string;
  sections: string[];
  sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> };
};

export default function Experience({
  activeSection,
  sections,
  sectionRefs,
}: ExperienceProps) {
  const [activated, setActivated] = useState(false);
  const [childrenctivated, setChildrenActivated] = useState(false);
  const sectionName = "experience";

  useEffect(() => {
    if (activated) {
      return;
    }
    if (
      sections.findIndex((section) => section === activeSection) >=
      sections.findIndex((section) => section === sectionName)
    ) {
      setActivated(true);
      setTimeout(() => {
        setChildrenActivated(true);
      }, 600);
    }
  }, [activated, sections, activeSection]);

  const experienceCards = experience.map((job) => (
    <motion.div variants={fadeIn as Variants} className="mb-8">
      <MagicCard gradientColor={"#b1b1b133"} className="bg-white">
        <CardContent className="p-6">
          <h3 className="text-xl font-semibold">{job.title}</h3>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            {job.company} | {job.dates}
          </p>
          {job.actions && (
            <ul className="list-disc list-inside mb-4">
              {job.actions.map((action) => (
                <li key={action}>{action}</li>
              ))}
            </ul>
          )}
          {job.skills && (
            <div className="flex flex-wrap gap-2">
              {job.skills?.map((tag) => (
                <span
                  key={tag}
                  className="bg-gray-200 dark:bg-gray-700 text-sm px-2 py-1 rounded"
                >
                  {tag}
                </span>
              ))}
            </div>
          )}
        </CardContent>
      </MagicCard>
    </motion.div>
  ));

  return (
    <motion.section
      ref={sectionRefs.experience}
      id="experience"
      className="py-16 px-4 bg-gray-200 dark:bg-gray-800"
      {...fadeIn}
    >
      <div className="container mx-auto">
        {activated ? (
          <>
            <motion.h2
              className="text-3xl font-bold mb-8 text-center"
              {...titleAnimation}
            >
              Work Experience
            </motion.h2>
            {childrenctivated ? (
              <motion.div
                variants={stagger}
                initial="initial"
                animate="animate"
              >
                {experienceCards}
              </motion.div>
            ) : (
              <div style={{ opacity: 0 }}>{experienceCards}</div>
            )}
          </>
        ) : (
          <>
            <h2 className="text-transparent text-3xl font-bold mb-8">.</h2>
            <div style={{ opacity: 0 }}>{experienceCards}</div>
          </>
        )}
      </div>
    </motion.section>
  );
}
