import { fadeIn, stagger, titleAnimation } from "@/animation.utils";
import { motion, Variants } from "framer-motion";
import { Card, CardContent } from "../ui/card";
import { useEffect, useState } from "react";

const education = [
  {
    dates: "2013 - 2017",
    title: "Master's Degree in Computer Science",
    institution: "University of Buenos Aires",
  },
  {
    dates: "2010 - 2013",
    title: "Bachelor's Degree in Computer Science",
    institution: "University of Buenos Aires",
  },
];

type EducationProps = {
  activeSection: string;
  sections: string[];
  sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> };
};

const educationCards = education.map((item) => {
  return (
    <motion.div variants={fadeIn as Variants} className="mb-8">
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-semibold">{item.title}</h3>
          <p className="text-gray-600 dark:text-gray-400">
            {item.institution} | {item.dates}
          </p>
        </CardContent>
      </Card>
    </motion.div>
  );
});

export default function Education({
  activeSection,
  sections,
  sectionRefs,
}: EducationProps) {
  const [activated, setActivated] = useState(false);
  const [childrenctivated, setChildrenActivated] = useState(false);
  const sectionName = "education";

  useEffect(() => {
    if (activated) {
      return;
    }
    if (
      sections.findIndex((section) => section === activeSection) >=
      sections.findIndex((section) => section === sectionName)
    ) {
      setActivated(true);
      setTimeout(() => {
        setChildrenActivated(true);
      }, 600);
    }
  }, [activated, sections, activeSection]);

  return (
    <motion.section
      ref={sectionRefs.education}
      id="education"
      className="py-16 px-4 bg-gray-200 dark:bg-gray-800"
      {...fadeIn}
    >
      <div className="container mx-auto">
        {activated ? (
          <>
            <motion.h2
              className="text-3xl font-bold mb-8 text-center"
              {...titleAnimation}
            >
              Education
            </motion.h2>
            {childrenctivated ? (
              <motion.div
                variants={stagger}
                initial="initial"
                animate="animate"
              >
                {educationCards}
              </motion.div>
            ) : (
              <div style={{ opacity: 0 }}>{educationCards}</div>
            )}
          </>
        ) : (
          <>
            <h2 className="text-transparent text-3xl font-bold mb-8">.</h2>
            <div style={{ opacity: 0 }}>{educationCards}</div>
          </>
        )}
      </div>
    </motion.section>
  );
}
