import { motion } from "framer-motion";
import { Ref } from "react";
import ShimmerButton from "@/components/magicui/shimmer-button";
import AnimatedGridPattern from "@/components/magicui/animated-grid-pattern";
import { cn } from "@/lib/utils";
import { ArrowDownIcon } from "lucide-react";

type HeroProps = {
  ref: Ref<HTMLElement> | undefined;
  scrollToSection: (name: string) => void;
};

export default function Hero({ ref, scrollToSection }: HeroProps) {
  return (
    <motion.section
      ref={ref}
      id="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="h-screen flex flex-col justify-center items-center text-center px-4 pt-16"
    >
      <AnimatedGridPattern
        numSquares={30}
        maxOpacity={0.1}
        duration={3}
        repeatDelay={1}
        className={cn(
          "[mask-image:radial-gradient(500px_circle_at_center,white,transparent)]",
          "inset-x-0 inset-y-[-30%] h-[200%] skew-y-12"
        )}
      />
      <motion.h1
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="text-5xl md:text-7xl font-bold mb-6"
      >
        <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300/80 bg-clip-text text-center text-8xl font-semibold leading-none text-transparent dark:from-white dark:to-slate-900/10">
          Alejandro Rebecchi
        </span>
      </motion.h1>
      <motion.p
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="text-xl md:text-2xl mb-8"
      >
        Senior Full Stack Developer
      </motion.p>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <ShimmerButton
          className="shadow-2xl"
          onClick={() => scrollToSection("projects")}
        >
          <span className="whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
            View My Work
          </span>
        </ShimmerButton>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="absolute bottom-10"
      >
        <ArrowDownIcon
          className="w-8 h-8 animate-bounce"
          onClick={() => scrollToSection("about")}
        />
      </motion.div>
    </motion.section>
  );
}
