import { Button } from "../ui/button";

type NavigationProps = {
  activeSection: string;
  sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> };
  scrollToSection: (name: string) => void;
};

export default function Navigation({
  activeSection,
  sectionRefs,
  scrollToSection,
}: NavigationProps) {
  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 backdrop-blur-[12px] shadow-md">
        <ul className="flex justify-center space-x-4 p-4">
          {Object.keys(sectionRefs).map((section) => (
            <li key={section}>
              <Button
                variant={activeSection === section ? "default" : "ghost"}
                onClick={() => scrollToSection(section)}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Button>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
