"use client";

import { useState, useEffect, useRef } from "react";

import "./styles/global.scss";
import Hero from "./components/home/hero";
import Navigation from "./components/home/nav";
import About from "./components/home/about";
import Skills from "./components/home/skills";
import Experience from "./components/home/experience";
import Projects from "./components/home/projects";
import Education from "./components/home/education";
import Footer from "./components/home/footer";

type OO = {
  root: null;
  rootMargin: string;
  threshold: number;
};

const sections = [
  "home",
  "about",
  "skills",
  "experience",
  "projects",
  "education",
];

export default function NewCv() {
  const [activeSection, setActiveSection] = useState("home");
  const sectionRefs: { [key: string]: any } = {
    home: useRef(null),
    about: useRef(null),
    skills: useRef(null),
    experience: useRef(null),
    projects: useRef(null),
    education: useRef(null),
  };

  useEffect(() => {
    const observerOptions: OO = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observerCallback: IntersectionObserverCallback = (
      entries: IntersectionObserverEntry[]
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
        return;
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, []);

  const scrollToSection = (sectionId: string) => {
    sectionRefs[sectionId].current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800 text-gray-800 dark:text-gray-200">
      <Navigation
        activeSection={activeSection}
        scrollToSection={scrollToSection}
        sectionRefs={sectionRefs}
      />

      <Hero ref={sectionRefs.home} scrollToSection={scrollToSection} />
      <About
        activeSection={activeSection}
        sections={sections}
        sectionRefs={sectionRefs}
      />
      <Skills
        activeSection={activeSection}
        sections={sections}
        sectionRefs={sectionRefs}
      />
      <Experience
        activeSection={activeSection}
        sections={sections}
        sectionRefs={sectionRefs}
      />
      <Projects
        activeSection={activeSection}
        sectionRefs={sectionRefs}
        sections={sections}
      />
      <Education
        activeSection={activeSection}
        sections={sections}
        sectionRefs={sectionRefs}
      />
      <Footer />
    </div>
  );
}
